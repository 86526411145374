
import Vue from 'vue';
import LoginCard from '@/components/LoginCard.vue';

export default Vue.extend({
	components: {
		LoginCard
	},
  data: () => ({
    loading: false,
  }),
	methods: {
		async handleLoginSubmit({ email = '', password = '' }) {
      this.loading = true;
      const login = await this.$store.dispatch('login', { email, password });

			if (login.success) {
				this.$router.push('/');
      }
      
      this.loading = false;
		}
	}
});
